<template>
  <div id="textree-more" class="container-more" :style="{color: textColor}">
    <div class="more-inner" @click="nextPage">
      <div class="more-text">{{text}}</div>
      <img class="more-arrow" :src="arrow"/>
    </div>
    <div class="more-scroll" v-if="hasScroll">
      <div class="icon animate__animated animate__shakeY animate__infinite" :style="{backgroundImage: `url(${scroll})`}"></div>
    </div>
  </div>
</template>

<script>
import store from '@/store'

export default {
  model: {
    prop: 'index',
    event: 'next'
  },
  data: () => ({
    currentIndex: 0,
    currentTop: window.top.scrollY,
    scrolled: false,
    pageWidth: window.innerWidth
  }),
  computed: {
    total: () => store.state.more.pages.length,
    index: {
      get: () => store.state.more.currentIndex,
      set: (i) => store.commit('SET_MORE_CURRENT_INDEX', i)
    },
    textColor: function () {
      if (store.state.more.theme == 'light') {
        return store.state.more.light.color
      } else {
        return store.state.more.dark.color
      }
    },
    text: function () {
      if (this.index < (this.total - 1)) {
        return store.state.more.text.more
      } else {
        return store.state.more.text.back
      }
    },
    arrow: function () {
      if (this.index < (this.total - 1)) {
        if (store.state.more.theme == 'light') {
          return store.state.more.light.arrow.down
        } else {
          return store.state.more.dark.arrow.down
        }
      } else {
        if (store.state.more.theme == 'light') {
          return store.state.more.light.arrow.up
        } else {
          return store.state.more.dark.arrow.up
        }
      }
    },
    scroll: function () {
      if (store.state.more.theme == 'light') {
        if (this.index == 0) {
          return store.state.more.light.scroll.down
        } else if (this.index < (this.total - 1)) {
          return store.state.more.light.scroll.mid
        } else {
          return store.state.more.light.scroll.up
        }
      } else {
        if (this.index == 0) {
          return store.state.more.dark.scroll.down
        } else if (this.index < (this.total - 1)) {
          return store.state.more.dark.scroll.mid
        } else {
          return store.state.more.dark.scroll.up
        }
      }
    },
    hasScroll: () => store.state.more.hasScroll
  },
  mounted() {
    var self = this
    if (this.hasScroll && this.pageWidth > 768) {
      window.addEventListener('wheel', function(event) {
        self.onScroll(event)
      })
    } else {
      document.getElementById('textree-more').addEventListener('wheel', function(event) {
        self.onScroll(event)
      })
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    nextPage: function () {
      if (this.index < this.total - 1) {
        this.index++
      } else {
        this.index = 0
      }
    },
    onScroll: function (event) {
      if (event.deltaY < 0 && this.index > 0) {
        this.index--
      }
      if (event.deltaY > 0 && this.index < this.total - 1) {
        this.index++
      }
    }
  },
  watch: {
    index: function (new_index) {
      if (this.currentIndex != new_index) {
        this.$emit('next', new_index)
        this.currentIndex = new_index
      }
    }
  }
}
</script>

<style scoped>
.animate__animated {
  --animate-duration: 10s;
}
.container-more {
  position: fixed;
  top: 0;
  bottom: 0;
  left: calc(1600px + (100% - 1600px) / 2);
  width: calc((100% - 1600px) / 2);
  max-width: 160px;
  height: 100%;
  display: flex;
  align-items: flex-end;
  border-left: 1px solid;
  border-right: 1px solid;
}
.more-inner {
  border-top: 1px solid;
  height: 40%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
}

.more-text {
  transform: rotate(270deg);
  text-transform: uppercase;
  letter-spacing: 100;
}
.more-arrow {
  width: 32px;
  height: 32px;
}

div.more-inner:hover div.more-text {
  font-weight: bold;
}
div.more-inner:hover img.more-arrow {
  width: 36px;
  height: 36px;
}

div.more-scroll {
  position: fixed;
  bottom: 8px;
  left: calc(50% - 23px);
  right: calc(50% - 23px);
  z-index: -1;
}
div.more-scroll div.icon {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 23px;
  height: 100px;
  margin: auto;
}

@media(max-width: 1860px) {
  .container-more {
    display: none;
  }
}
</style>
